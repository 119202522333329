// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { createApp } from 'vue';

import AccountSecurity from '../javascript/pages/accounts/AccountSecurity.vue';
if (window.location.pathname == '/account/security') {
  createApp(AccountSecurity).mount('#sl_vue_account_security');
}

import ReorderDataChoiceList from '../javascript/components/ReorderDataChoiceList.vue';
if (window.location.pathname.includes('pca_fields/')) {
  createApp(ReorderDataChoiceList).mount("#sl_vue_reorder_data_choice_list")
}

import EditPlinkProjectNotes from '../javascript/components/EditPlinkProjectNotes.vue';
if (window.location.pathname.includes('projects/')) {
  createApp(EditPlinkProjectNotes).mount('#sl_vue_edit_plink_project_notes');
}

import OrderFormServicesIndex from '../javascript/pages/order_form_services/Index.vue';
if (window.location.pathname.includes('/order_form_services')) {
  createApp(OrderFormServicesIndex).mount('#sl_vue_order_form_services_index');
}

import OrderFormServicesEdit from '../javascript/pages/order_form_services/Edit.vue';
if (window.location.pathname.includes('/order_form_services') && window.location.pathname.includes('/edit')) {
  createApp(OrderFormServicesEdit).mount('#sl_vue_order_form_services_edit');
}

import RegenerateProjectdocLink from '../javascript/components/RegenerateProjectdocLink.vue'
if (window.location.pathname.includes('projects/')) {
  createApp(RegenerateProjectdocLink).mount('#sl_vue_regenerate_link_modal');
}

import SearchEsgChecklistFields from '../javascript/components/SearchEsgChecklistFields.vue'
if (['/esg_checklist_templates/all_checklist_templates'].includes(window.location.pathname)) {
  createApp(SearchEsgChecklistFields).mount('#sl_vue_search_esg_checklist_fields');
}

import PhotosTransferModal from '../javascript/components/PhotosTransferModal.vue'
if (['/report_datas/project_photos_tab'].includes(window.location.pathname)) {
  createApp(PhotosTransferModal).mount('#sl_vue_photos_transfer_modal');
}

import MultiPhotoMoveModal from '../javascript/components/MultiPhotoMoveModal.vue'
if (['/report_datas/project_photos_tab'].includes(window.location.pathname)) {
  createApp(MultiPhotoMoveModal).mount('#sl_vue_multi_photo_move_modal');
}

import CostDataTransferModal from '../javascript/components/CostDataTransferModal.vue'
if (['/rmp_data_items/rmp_data'].includes(window.location.pathname)) {
  createApp(CostDataTransferModal).mount('#sl_vue_cost_data_transfer_modal');
}

import CommunicationLogDataTransferModal from '../javascript/components/CommunicationLogDataTransferModal.vue'
if (['/communication_logs/communication_log'].includes(window.location.pathname)) {
  createApp(CommunicationLogDataTransferModal).mount('#sl_vue_communication_log_data_transfer_modal');
}

// Controller: Admin, Action: dbadmin
import Dbadmin from '../javascript/pages/admin/Dbadmin.vue';
if (window.location.pathname == '/admin/dbadmin') {
  createApp(Dbadmin).mount('#sl_vue_dbadmin');
}

// Controller: ClientPortal, Action: document_upload
import DocumentUpload from '../javascript/pages/client_portal/DocumentUpload.vue';
if (window.location.pathname == '/client_portal/document_upload') {
  createApp(DocumentUpload).mount('#sl_document_upload');
}

// Controller: Users, Action: edit_additional_selectable_clients
import EditAdditionalSelectableClients from '../javascript/pages/users/EditAdditionalSelectableClients.vue';
if (window.location.pathname == '/users/edit_additional_selectable_clients') {
  createApp(EditAdditionalSelectableClients).mount('#sl_vue_edit_additional_selectable_clients');
}

// Controller: zoning_data_items and cpm_data_items, Action: working_documents_tab
import WorkingDocumentsTab from '../javascript/pages/WorkingDocumentsTab.vue';
if (window.location.pathname == '/zoning_data_items/working_documents_tab') {
  createApp(WorkingDocumentsTab).mount('#sl_vue_working_documents_tab');
} else if (window.location.pathname == '/cpm_data_items/working_documents_tab') {
  createApp(WorkingDocumentsTab).mount('#sl_vue_working_documents_tab');
} else if (window.location.pathname == '/rmp_data_items/working_documents_tab') {
  createApp(WorkingDocumentsTab).mount('#sl_vue_working_documents_tab');
}

// **********  Energy Pages ***************
import BaselineMetrics from '../javascript/pages/partner_esg/BaselineMetrics.vue'
if (window.location.pathname.includes('/baseline_metrics')) {
  createApp(BaselineMetrics).mount('#sl_vue_baseline_metrics');
}

// Controller: energy_efficiency_measures, Action: vue_index
import MeasuresTable from '../javascript/pages/partner_esg/MeasuresTable.vue'
if (window.location.pathname == '/decarbonization_goal_results') {
  createApp(MeasuresTable).mount('#sl_vue_energy_efficiency_measures_table');
}

// Controller: ewems, Action: vue_index
import EwemsTable from '../javascript/pages/partner_esg/EwemsTable.vue'
if (window.location.pathname == '/ewems') {
  createApp(EwemsTable).mount('#sl_vue_ewems_table');
}


// controller: decarb_custom_pathways, Action: vue_index
import CustomPathway from '../javascript/pages/partner_esg/CustomPathway.vue'
if (window.location.pathname == '/decarb_custom_pathways') {
  createApp(CustomPathway).mount('#sl_vue_decarb_custom_pathways');
}

import CarbonOffsets from '../javascript/pages/partner_esg/CarbonOffsets.vue'
if (window.location.pathname == '/decarbonization_goal_results') {
  createApp(CarbonOffsets).mount('#sl_vue_carbon_offsets');
}

import EnergyOffsets from '../javascript/pages/partner_esg/EnergyOffsets.vue'
if (window.location.pathname == '/decarbonization_goal_results') {
  createApp(EnergyOffsets).mount('#sl_vue_energy_offsets');
}

import EnergyEfficiencyCalculations from '../javascript/pages/partner_esg/EnergyEfficiencyCalculations.vue'
if (window.location.pathname == '/decarbonization_goal_results/calculations') {
  createApp(EnergyEfficiencyCalculations).mount('#sl_vue_energy_efficiency_calculations');
}

// Controller: espm_properties, Action: raw_data_tab
import EspmProperties from '../javascript/pages/partner_esg/energy_star/EspmProperties.vue';
if (window.location.pathname == '/espm_properties') {
  createApp(EspmProperties).mount('#sl_vue_espm_properties');
}

import EspmTenantIndex from '../javascript/pages/partner_esg/energy_star/EspmTenantIndex.vue';
if (window.location.pathname == '/espm_tenants') {
  createApp(EspmTenantIndex).mount('#sl_vue_espm_tenant_index');
}

// Controller: egrid_subregions, Action: index
import EgridSubregionsIndex from '../javascript/pages/partner_esg/EgridSubregionsIndex.vue';
if (window.location.pathname == '/egrid_subregions') {
  createApp(EgridSubregionsIndex).mount('#sl_vue_egrid_subregions_index');
}
// ****************************************************************************

// Controller: zoning_data_items and cpm_data_items, Action: progress_tab
import ProgressTab from '../javascript/pages/ProgressTab.vue';
if (window.location.pathname == '/cpm_data_items/progress_tab') {
  createApp(ProgressTab).mount('#sl_vue_progress_tab');
} else if (window.location.pathname == '/zoning_data_items/progress_tab') {
  createApp(ProgressTab).mount('#sl_vue_progress_tab');
}

import OrderForm from '../javascript/pages/project_orders/OrderForm.vue';
if (window.location.pathname == '/project_orders/order_form') {
  createApp(OrderForm).mount('#sl_vue_order_form');
}

import DataItemCommentsModal from '../javascript/pages/data_item_comments/DataItemCommentsModal.vue';
if (['/report_datas/pca_data', '/report_datas/seismic_data', '/esa_data_items/esa_data',
     '/zoning_data_items/zoning_data', '/cpm_data_items/cpm_data',
     '/rmp_data_items/rmp_data', '/esg_checklists/esg_checklist'].includes(window.location.pathname)) {
  createApp(DataItemCommentsModal).mount('#sl_vue_data_item_comment_modal');
}

import DataItemCommentsIndex from '../javascript/pages/data_item_comments/DataItemCommentsIndex.vue';
if (window.location.pathname == '/data_item_comments') {
  createApp(DataItemCommentsIndex).mount('#sl_vue_data_item_comments_index');
}

import PortfolioBluelynxInstructions from '../javascript/pages/portfolio_bluelynx_instructions/Index.vue';
if (window.location.pathname == '/portfolios/bluelynx_instructions') {
  createApp(PortfolioBluelynxInstructions).mount('#sl_vue_portfolio_bluelynx_instructions');
}

import PortfolioBluelynxInstructionsPopUp from '../javascript/pages/portfolio_bluelynx_instructions/PortfolioBluelynxInstructionsPopUp.vue';
if (['/report_datas/pca_data', '/esa_data_items/esa_data'].includes(window.location.pathname)) {
  createApp(PortfolioBluelynxInstructionsPopUp).mount('#sl_vue_portfolio_bluelynx_instructions_pop_up');
}

import ClientScopeIndex from '../javascript/pages/client_scopes/Index.vue';
if (window.location.pathname == '/client_scopes') {
  createApp(ClientScopeIndex).mount('#sl-vue-client-scopes-index');
}

// PCA Equity Cost Database ***************************************************
import PcaCostFactorIndex from '../javascript/pages/pca_equity_costs/PcaCostFactorIndex.vue';
if (window.location.pathname == '/pca_cost_factors') {
  createApp(PcaCostFactorIndex).mount('#sl_vue_pca_cost_factor_index');
}

import PcaCostFactorVersionIndex from '../javascript/pages/pca_equity_costs/PcaCostFactorVersionIndex.vue';
if (window.location.pathname == '/pca_cost_factor_versions') {
  createApp(PcaCostFactorVersionIndex).mount('#sl_vue_pca_cost_factor_version_index');
}

import PcaCostFactorVersionShow from '../javascript/pages/pca_equity_costs/PcaCostFactorVersionShow.vue';
if (window.location.pathname.includes('/pca_cost_factor_versions')) {
  createApp(PcaCostFactorVersionShow).mount('#sl_vue_pca_cost_factor_version_show');
}

import PcaEquityCostIndex from '../javascript/pages/pca_equity_costs/PcaEquityCostIndex.vue';
if (window.location.pathname == '/pca_equity_costs') {
  createApp(PcaEquityCostIndex).mount('#sl_vue_pca_equity_cost_index');
}

import PcaEquityCostImport from '../javascript/pages/pca_equity_costs/PcaEquityCostImport.vue';
if (window.location.pathname == '/pca_equity_costs/import_costs') {
  createApp(PcaEquityCostImport).mount('#sl_vue_pca_equity_cost_import');
}

import PcaCostFactorCity from '../javascript/pages/pca_equity_costs/PcaCostFactorCity.vue';
if (window.location.pathname.includes('/pca_cost_factor_cities')) {
  createApp(PcaCostFactorCity).mount('#sl_vue_pca_cost_factor_city');
}

// ****************************************************************************

// View: to_send_email
import ToSendEmail from '../javascript/pages/projects/ToSendEmail.vue';
if (window.location.pathname == '/projects/to_send_email') {
  createApp(ToSendEmail).mount('#sl_vue_to_send_email');
}

import EsaReportTable from '../javascript/pages/report_tables/esa/EsaReportTable.vue';
if (window.location.pathname.includes('/esa_report_tables/show_table') ||
    window.location.pathname.includes('/big_esa_report_tables/show_table')) {
  createApp(EsaReportTable).mount('#sl_vue_esa_report_table');
}

import PcaReportTable from '../javascript/pages/report_tables/pca/PcaReportTable.vue';
if (window.location.pathname.includes('/pca_report_tables/show_table')) {
  createApp(PcaReportTable).mount('#sl_vue_pca_report_table');
}

import ZoningReportTable from '../javascript/pages/report_tables/zoning/ZoningReportTable.vue';
if (window.location.pathname.includes('/zoning_report_tables/edit_table')) {
  createApp(ZoningReportTable).mount('#sl_vue_zoning_report_table');
}

// ****************************************************************************

import SiteMap from '../javascript/pages/SiteMap.vue';
if (window.location.pathname.includes('/site_maps/project_tab')) {
  createApp(SiteMap).mount('#sl_vue_site_map');
}

import BluelynxJsonDataImport from '../javascript/pages/importdocs/BluelynxJsonDataImport.vue';
if (window.location.pathname == '/importdocs/bluelynx_json_data_import') {
  createApp(BluelynxJsonDataImport).mount('#sl_vue_bluelynx_json_data_import');
}

import DataTransferLogPopUp from '../javascript/pages/data_transfer_logs/DataTransferLogPopUp.vue';
if (['/report_datas/pca_data', '/esa_data_items/esa_data'].includes(window.location.pathname)) {
  createApp(DataTransferLogPopUp).mount('#sl_vue_data_transfer_log_pop_up');
}
